import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class CraknClientsEditSfgsConfigRoute extends Route {
  queryParams = {
    template_page: {
      refreshModel: true
    },
    templates_per_page: {
      refreshModel: true
    }
  }

  model() {
    const clientId = this.modelFor('authenticated.crakn.clients.edit').get('id');

    return this.store.findRecord(
      'v2/client', clientId, {
        include: 'contract-templates'
      });
  }

  async setupController(controller, model) {
    super.setupController(...arguments);
    const systemSfgsTemplates = this.store.query('v2/systemSfgsTemplate', {});

    controller.set('systemSfgsTemplates', systemSfgsTemplates);
    controller.set('contractTemplates', await this.getContractTemplates(model.id));
    controller.set('client', model);
  }

  async getContractTemplates(modelId) {
    return this.store.query('v2/contractTemplate', {
      client_id: modelId
    });
  }

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      const resetParams = ![
        'authenticated.crakn.manage.clients'
      ].includes(transition.targetName);

      if (resetParams) {
        controller.setProperties({
          template_page: 1
        });
      }
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}

import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import SearchController from 'crakn/controllers/search';
import { formatFloat } from 'crakn/utils/data-helpers';
import { validator, buildValidations } from 'ember-cp-validations';
import moment from 'moment';
import  checkStatusOptions from 'crakn/utils/check-status';

const Validations = buildValidations({
  checkNumber: validator('presence', {
    presence: true
  })
});

export default class AuthenticatedChecksController extends SearchController.extend(
  Validations){
    @service flashes;
    @service intl;
    @service api;
    @service router;
    @service store;

    queryParams = ['query', 'page', 'archived', 'sort_by', 'order', 'start_date', 'end_date', 'bank_account_id', 'status'];

    @tracked isChecksEmpty;
    @tracked archivingCheck = null;
    @tracked printingCheck = null;
    @tracked voidingCheck = null;

    @tracked bankAccountOptions;
    @tracked sort_by = null;
    @tracked order = null;
    @tracked start_date;
    @tracked end_date;
    @tracked bank_account_id;
    @tracked status;

    get zeroChecksMessage() {
      const zeroChecksMatchingSearchMessage = this.intl.t('authenticated.checks.index.search.messages.noChecksFoundMatchingSeach');
      const zeroChecksAddedMessage = this.intl.t('authenticated.checks.index.table.messages.noChecksAvailable');
  
      return this.isChecksEmpty
      ? (this.search
        ? zeroChecksMatchingSearchMessage
        : zeroChecksAddedMessage)
      : zeroChecksMatchingSearchMessage;
    }

    get checkStatusOptions(){
      return this.bankAccountOptions ? checkStatusOptions: [];
    }

    @action
    handleCheckAction(check, action) {
      set(this, 'checkNumber', check.number);
      this.validate().then(({ validations }) => {
        if (validations.isValid) {
          if (action === 'print') set(this, 'printingCheck', check);
          else if (action === 'void') set(this, 'voidingCheck', check);
        }
        else {
          this.flashes.addError(this.intl.t('authenticated.checks.index.table.messages.validation.checkNumber'));
          this.router.transitionTo('authenticated.checks.check-details.edit', check.id, { queryParams: { action: action } });
        }
      });
    }

    @action
    confirmVoidingCheck(check) {
        const checkStatus = check.status;
        set(check, 'status', 'voided');
        set(this, 'voidingCheck', null);
        check.save().then(() => {
            this.flashes.addSuccess(this.intl.t('authenticated.checks.index.table.messages.voidCheck.success'));
        }).catch(() => {
            set(check, 'status', checkStatus);            
            this.flashes.addError(this.intl.t('authenticated.checks.index.table.messages.error'));
        })
    }

    @action
    confirmArchivingCheck(check) {
        set(check, 'archivedAt', moment());
        set(this, 'archivingCheck', null);
        check.save().then(() => {
            this.flashes.addSuccess(this.intl.t('authenticated.checks.index.table.messages.archiveCheck.success'));
            this.store.query('tribute-pay/v1/check', { }, { reload: true }).then((checks) => set(this, 'model', checks));
        }).catch(() => {
            set(check, 'archivedAt', null);
            this.flashes.addError(this.intl.t('authenticated.checks.index.table.messages.error'));
        })
    }

    @action
    async confirmPrintingCheck(check) {
      set(this, 'printingCheck', null);
      try{
        const response = await this.api.json.post(
          `tfe/documents/${check.bankAccount.checkTemplateId}/generate`,
          {
            body: {
              bank_account_id: check.bankAccountId,
              form_data: {
                  amount: formatFloat(check.amount, false, false, 2, false),
                  bank_account_id: check.bankAccountId,
                  check_id: check.id,
                  date: check.date.format('YYYY-MM-DD'),
                  payee_type: check.payeeType,
                  memo: check.memo,
                  number: check.number,
                  payee_id: check.payeeId,
                  payee_name: check.payeeName
                 
              }
            }
          }
        );

        if(!response.ok){
          throw new Error(response);
        }

        this.router.transitionTo('authenticated.checks.check-details.edit', check.id);
      }catch(error){
        this.flashes.addError(this.intl.t('authenticated.checks.new.messages.print.error'));
      }
    }

    @action
    setStartDate(date){
      set(this, 'start_date', date? moment(date).format('YYYY-MM-DD'): null);
    }

    @action
    setEndDate(date){
      set(this, 'end_date', date? moment(date).format('YYYY-MM-DD'): null);
    }

    @action
    setBankAccount(bankAccount){
      set(this, 'bank_account_id', bankAccount?.id);
    }

    @action
    setStatus(status){
      set(this, 'status', status?.id);
    }

}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { filterBy, mapBy } from '@ember/object/computed';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CraknClientsEditSfgsConfigController extends Controller {
  @controller('authenticated/crakn/clients/edit') edit;

  @service flashes;
  @service session;
  @service router;
  @service intl;

  @tracked showValidation = false;
  @tracked template_page = 1;
  @tracked templates_per_page = 10;

  @filterBy('contractTemplates', 'isNew', false) savedContractTemplates;
  @mapBy('systemSfgsTemplates', 'name') systemSfgsTemplateNames;

  menuPlacement = ['left', 'bottom', 'top', 'right'];
  queryParams = ['template_page', 'templates_per_page'];
  offset = [67, -30];

  get contractLabel() {
    return this.session.currentClient.relabel_contract_to_invoice ? 
    `Invoice` : `Contract`;
  }

  @action
  buildContractTemplate() {
    const template = this.store.createRecord('v2/contractTemplate', {
      client: this.client // client is required since this can be a TMS client
    });

    this.transitionTo(template);
  }

  @action
  saveContractTemplate(template, event) {
    event.preventDefault();

    template.validate().then(({ validations }) => {
      if (validations.get('isValid')) {
        this.showValidation = false;

        template
          .save()
          .then(() => {
            this.flashes.addSuccess(`${this.contractLabel} Template saved!`);
            this.refreshModel();
          })
          .catch(() => {
            this.flashes.addError(
              `Oops! There was an error saving this ${this.contractLabel} Template.`
            );
          });
      } else {
        this.showValidation = true;
      }
    });
  }

  @action
  cancelContractTemplate(template) {
    template.rollbackAttributes();
  }

  @action
  deleteContractTemplate(template) {
    template
      .destroyRecord()
      .then(() => {
        this.flashes.addSuccess(`${this.contractLabel} Template deleted!`);
        this.refreshModel();
      })
      .catch(() => {
        this.flashes.addError(
          `Oops! There was an error deleting this ${this.contractLabel} Template.`
        );
      });
  }

  @action
  transitionTo(template) {
    this.router.transitionTo('authenticated.crakn.clients.sfgs-config.edit.overview', template);
  }
}

import { helper } from '@ember/component/helper';

const iconMap = {
    Voided: { type: 'MdIcon', icon: 'block' },
    Printed: { type: 'SvgIcon', icon: 'print-connect' },
    Created: { type: 'SvgIcon', icon: 'checkbook' },
    Updated: { type: 'SvgIcon', icon: 'undo' },
    Archived: { type: 'SvgIcon', icon: 'inventory' }
  };

export default helper(function auditLogIconHelper([action]) {
    return iconMap[action] || iconMap['Updated'];
});

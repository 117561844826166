import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AdminLocationsEditUserAccessRoute extends SearchRoute {
  @service store;

  queryParams = {
    page: {
      refreshModel: true
    },
    organization_id: {
      refreshModel: true
    },
    user_name: {
      refreshModel: true
    },
    popout_user_name: {
      refreshModel: true
    }
  }

  modelType = 'v2/userLocation';
  routeRegex = /authenticated.admin.location.edit.user-access/;

  async model(params) {
    const locationId = this.modelFor('authenticated.admin.locations.edit').get('id');
    return await this.store.query(this.modelType, {
      include: 'user,user.name',
      location_id: locationId,
      archived: false,
      ...params
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.locationId = this.modelFor('authenticated.admin.locations.edit').get('id');
    controller.location = this.store.findRecord('v2/location', controller.locationId);

    controller.getUnassignedUsers();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('organization_id', null);
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}

/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { reads } from '@ember/object/computed';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { cleanPrice, formatFloat } from 'crakn/utils/data-helpers';
import { validator, buildValidations } from 'ember-cp-validations';
import moment from 'moment';

const Validations = buildValidations({
  'model.check.amount': [
    validator('number', {
      gt: 0.00,
      allowString: true,
      message: reads('model.checkAmountValidationMessage'),
      disabled: computed('model.action', function () {
        return this.model.action == 'void';
      })
    }),
    validator('number', {
      allowString: true,
      gte: 0.00,
      message: reads('model.checkAmountValidationMessage'),
      disabled: computed('model.action', function () {
        return this.model.action != 'void';
      })
    }),
  ],
  'model.check.bankAccountId': validator('presence', {
    presence: true
  }),
  'model.check.date': validator('presence', {
    presence: true,
    message: reads('model.checkDateValidationMessage'),
    disabled: computed('model.action', function () {
      return this.model.action == 'void';
    })
  }),
  'model.check.number': [
    validator('presence', {
      presence: true,
      message: reads('model.checkNumberValidationMessage'),
      disabled: computed('model.action', function () {
        return this.model.action == 'draft';
      })
    }),
    validator('number', {
      integer: true,
      gt: 0,
      allowString: true,
      message: reads('model.checkNumberValidationMessage'),
      disabled: computed('{model.action,model.check.number}', function () {
        return !(this.model.check.number ?? false);
      })
    })],
  'model.check.payeeName': validator('presence', {
    presence: true,
    disabled: computed('{model.check.payeeType,model.action}', function () {
      return (this.model.check.payeeType != 'one_time_payee' || this.model.action == 'void');
    })
  }),
  payeeId: validator('presence', {
    presence: true,
    disabled: computed('{model.check.payeeType,model.action}', function () {
      return (this.model.check.payeeType == 'one_time_payee' || this.model.action == 'void');
    })
  }),
});

export default class AuthenticatedChecksCheckDetailsEditController extends Controller.extend(
  Validations) {
    @service api;
    @service flashes;
    @service intl;
    @service router;
    @service api;
    @service store;

    queryParams = ['action'];

    @tracked action;
    @tracked checkDate;
    @tracked checkNumber;
    @tracked checkStatus;
    @tracked dateRange;
    @tracked isPrintingCheck = false;
    @tracked isReadyToDownload = false;
    @tracked newPayee;
    @tracked payeeId;
    @tracked showValidations;
    @tracked showVoidConfirmDilog = false;
    @tracked showPrintConfirmDilog = false;
    @tracked timeout = 2000;

    get checkDateValidationMessage() {
      return this.intl.t('authenticated.checks.check-details.edit.messages.validation.checkDate', { dateRange: this.dateRange });
    }
  
    get checkAmountValidationMessage() {
      return this.intl.t('authenticated.checks.check-details.edit.messages.validation.checkAmount');
    }
  
    get checkNumberValidationMessage() {
      return this.intl.t('authenticated.checks.check-details.edit.messages.validation.checkNumber');
    }
  
    get check() {
      return this.model.check;
    }

    get hasDirtyAttributes() {
      return this.check.hasDirtyAttributes;
    }

    @action
    addNewPayee(value){
      set(this, 'newPayee', value);
      set(this, 'payeeId', value? value.id : '');
      set(this.model.check, 'payeeName', value? value.name: '');
    }

    @action
    updateBankAccount(bankAccountId) {
      set(this.model.check, 'bankAccountId', bankAccountId);
      set(this.model.check, 'date', moment());
      set(this, 'dateRange', 90);
      set(this.model.check, 'number', null);
  
      if (bankAccountId) {
        const bankAccount = this.model.bankAccounts.find(({ id }) => id === bankAccountId);
        set(this, 'dateRange', bankAccount.dateRange);
        set(this.model, 'bankAccount', bankAccount);

        if(bankAccountId == this.model.check.bankAccount?.id){  
          set(this.model.check, 'date', this.checkDate);
          set(this.model.check, 'number', this.checkNumber);
        }
        else{
          set(this.model.check, 'number', bankAccount.nextAvailableCheckNumber || bankAccount.startingCheckNumber);
        }
      }
    }

    @action
    updateCheckAmount(amount) {
      set(this.model.check, 'amount', amount? cleanPrice(amount) : 0);
    }

    @action
    updateCheckDate(checkDate) {
      const currentDate = this.checkDate ? moment(this.checkDate).format('YYYY-MM-DD') : null;
      const newDate = checkDate ? moment(checkDate).format('YYYY-MM-DD') : null;
    
      if (currentDate === newDate) set(this.model.check, 'date', this.checkDate);
      else set(this.model.check, 'date', newDate);
    }

    @action
    async saveContact(){
      await this.newPayee.save();
      set(this, 'payeeId', this.newPayee? this.newPayee.id : '');
      set(this.model.check, 'payeeName', this.newPayee ? this.newPayee.name : '');
      return this.newPayee;
    } 

    @action
    saveVendor(vendor){
      set(this, 'newPayee', vendor);
      set(this, 'payeeId', vendor? vendor.id : '');
      set(this.model.check, 'payeeName', vendor? vendor.name : '');
    }

    @action
    clearVendor(){
      set(this, 'newPayee', null);
      set(this, 'payeeId', '');
      set(this.model.check, 'payeeName', '');
    }

    @action
    updatePayeeType(payee){
      set(this.model.check, 'payeeType', payee);
      set(this.model.check, 'payeeName', '');
      set(this, 'newPayee', null);
      set(this, 'payeeId', '');
    }

    @action
    saveAsDraft() {
      set(this, 'action', 'draft');
      this.validate().then(({validations})=>{
        if (validations.get('isValid')){
          set(this, 'showValidations', false);
          set(this.model.check, 'payeeId', this.payeeId);
          set(this.model.check, 'payeeName', (this.model.check.payeeName || this.newPayee?.name) ?? '');
          this.model.check.save().then(()=>{
            this.flashes.addSuccess(this.intl.t('authenticated.checks.check-details.edit.messages.saveAsDraft.success'));
            this.router.transitionTo('authenticated.checks.index');
          }).catch((error)=>{
            const checkNumberError = error?.errors?.find((err) =>  err.source?.pointer === '/data/attributes/number');
            if (checkNumberError) {
              this.flashes.addError(this.intl.t('authenticated.checks.check-details.edit.messages.saveAsDraft.checkNumberError'));
            } else {
              this.flashes.addError(this.intl.t('authenticated.checks.check-details.edit.messages.saveAsDraft.error'));
            }
            });
        } else {
          set(this, 'showValidations', true);
        }
      });
    }
  
    @action
    voidCheck() {
      set(this, 'action', 'void');
      this.validate().then(({ validations }) => {
        if (validations.get('isValid')) {
          set(this, 'showValidations', false);
          set(this, 'showVoidConfirmDilog', true);
        }
        else {
          set(this, 'showValidations', true);
        }
      });

    }

    @action
    printCheck() {
      set(this, 'action', 'print');
      this.validate().then(({ validations }) => {
        if (validations.get('isValid')) {
          set(this, 'showValidations', false);
          set(this, 'showPrintConfirmDilog', true);
        }
        else {
          set(this, 'showValidations', true);
        }
      });

    }
  
    @action
    confirmVoidingCheck() {
      set(this, 'showVoidConfirmDilog', false);
      set(this.model.check, 'payeeId', this.payeeId);
      set(this.model.check, 'payeeName', (this.model.check.payeeName || this.newPayee?.name) ?? '');
      set(this.model.check, 'status', 'voided');
      this.model.check.save().then(() => {
        set(this, 'checkStatus', 'voided');
        this.flashes.addSuccess(this.intl.t('authenticated.checks.check-details.edit.messages.void.success'));
      }).catch((error) => {
        const checkNumberError = error?.errors?.find((err) => err.source?.pointer === '/data/attributes/number');
        if (checkNumberError) {
          this.flashes.addError(this.intl.t('authenticated.checks.check-details.edit.messages.void.checkNumberError'));
        } else {
          this.flashes.addError(this.intl.t('authenticated.checks.check-details.edit.messages.void.error'));
        }
        });
    }

    @action
    async confirmPrintingCheck() {
      set(this, 'showPrintConfirmDilog', false);
      set(this, 'isPrintingCheck', true );
      
      try{
        const response = await this.api.json.post(
          `tfe/documents/${this.model.bankAccount.checkTemplateId}/generate`,
          {
            body: {
              bank_account_id: this.check.bankAccountId,
              form_data: {
                  amount: formatFloat(this.check.amount, false, false, 2, false),
                  bank_account_id: this.check.bankAccountId,
                  check_id: this.check.id,
                  date: this.check.date.format('YYYY-MM-DD'),
                  payee_type: this.check.payeeType,
                  memo: this.check.memo,
                  number: this.check.number,
                  payee_id: this.payeeId,
                  payee_name: (this.check.payeeName || this.newPayee?.name) ?? '' 
              }
            }
          }
        );

        if(!response.ok){
          throw new Error(response.parsedJson.errors.number[0]);
        }

        await this.store.findRecord('tribute-pay/v1/check', this.check.id,{ reload: true });
        set(this, 'isPrintingCheck', false );
        set(this, 'checkStatus', 'printed');
        this.checkIfGenerationCompleted();
      }catch(error){
        set(this, 'isPrintingCheck', false );
        
        const checkNumberError = error.message === 'Check number has been used';
          if (checkNumberError) {
            this.flashes.addError(this.intl.t('authenticated.checks.new.messages.print.checkNumberError'));
          } else {
            this.flashes.addError(this.intl.t('authenticated.checks.new.messages.print.error'));
          }
      }
    }

    async checkIfGenerationCompleted() {
      if(this.check.status === 'printed'){
        try {
          const response = await this.store.findRecord('tfe/generatedDocument', this.check.generatedDocumentId, { reload: true });
          
          if (response.status === 'processing' || response.status === 'pending') {
            this.pollForCheck();
          } else {
            set(this, 'isReadyToDownload', true);
          }

        } catch (error) {
          this.flashes.addError(this.intl.t('authenticated.checks.new.messages.print.error'));
        }
      }
    }
    
    pollForCheck() {
      this.timeout += 500;
      later(this, this.checkIfGenerationCompleted, this.timeout);
    }

    @action
    async downloadCheck(){
      const button = event.target;
      button.disabled = true;

      try {
        const response = await this.api.get(`tfe/generated_documents/${this.model.check.generatedDocumentId}/download`);
        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');

          a.href = url;
          a.download = `${this.model.bankAccount.bankAccountName} - Check - ${this.model.check.number}.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          this.flashes.addError(this.intl.t('authenticated.checks.check-details.edit.banner.printed.messages.downloadError'));
        }
      } catch (error) {
        this.flashes.addError(this.intl.t('authenticated.checks.check-details.edit.banner.printed.messages.downloadError'));
      } finally {
        button.disabled = false;
      }
    }
}
